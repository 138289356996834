export const couponOrder = {
    namespaced: true,
    state: {
        order: null,
        progress: 1,
    },
    getters: {
        progress: (state) => {
            return state.progress;
        },
        orderState: (state) => {
            return state.order;
        },
    },
    mutations: {
        setProgress(state, progress) {
            state.progress = progress;
        },
        setCouponOrder(state, order) {
            state.order = order;
        },
    },
    actions: {
      
    },
};
