import { CheckOutApi } from '@/api';

export const orderManagement = {
    namespaced: true,
    state: {
        loading: false,
        checkOuts: [],
        currentCheckOut: null,
        meta: {
            pageCount: 1,
            currentPage: 1,
            totalCount: 0,
            perPage: 0,
        },
        paramsData: {
            discount: '',
            search: '',
            deleted: '',
            status: '',
            paymentType: '',
            selectedSortBy: 1,
            sortField: 'created_at',
            sortDirection: 'desc',
            mealkit: '',
            page: 1,
        },
    },
    getters: {
        loading: (state) => {
            return state.loading;
        },
        checkOuts: (state) => {
            return state.checkOuts;
        },
        meta: (state) => {
            return state.meta;
        },
        currentCheckOut: (state) => {
            return state.currentCheckOut;
        },
        params: (state) => {
            return state.paramsData;
        },
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },
        setCheckOuts(state, checkOuts) {
            state.checkOuts = checkOuts;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        setCurrentCheckOut(state, currentCheckOut) {
            state.currentCheckOut = currentCheckOut;
        },

        updateCheckOut(state, newCheckOut) {
            let checkOut = state.checkOuts.find((item) => item.id === newCheckOut.id);

            if (checkOut) {
                const index = state.checkOuts.indexOf(checkOut);
                state.checkOuts[index] = newCheckOut;
            }
        },

        updateParamsField(state, payload) {
            Object.entries(payload).forEach((element) => {
                state.paramsData[element[0]] = element[1];

                if (element[0] !== 'page') {
                    state.paramsData.page = 1;
                }
            });
        },
    },
    actions: {
        async getAllCheckOuts({ commit, state }, payload = {}) {
            commit('updateParamsField', payload);
            commit('setLoading', true);
            
            const response = await CheckOutApi.getAll(state.paramsData);

            const meta = {
                pageCount: response.meta.last_page,
                currentPage: response.meta.current_page,
                totalCount: response.meta.total,
                perPage: response.meta.to - response.meta.from + 1,
            };

            commit('setCheckOuts', response.data);
            commit('setMeta', meta);
            commit('setLoading', false);
        },

        async getCheckOut({ commit }, id) {
            const response = await CheckOutApi.show(id);

            commit('setCurrentCheckOut', response.data);
        },

        async updateCheckOut({ commit }, payload) {
            const response = await CheckOutApi.update(payload.id, payload.data);

            commit('setCurrentCheckOut', response.data);
            commit('updateCheckOut', response.data);
        },
    },
};
