
export default [
    {
        path: '/coupon-order',
        component: () => import('@/layouts/CouponOrderLayout'),
        name: 'couponOrderWrapper',
        redirect: { name: 'couponOrderKit' },
        children: [
            {
                path: '',
                component: () => import('@/pages/CouponOrder/CouponOrderPreferences.vue'),
                name: 'CouponOrderKit',
            },
        ],
    },
];
