import Api from './api';

export class CouponOrderApi {
    static store = async (data) => {
        const response = await Api.axios.post('coupon-orders/store', data);
        return response.data;
    };

    static payment = async (data) => {
        const response = await Api.axios.post('coupon-orders/pay', data);
        return response.data;
    };
}
